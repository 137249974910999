import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AggregatedProductModule,
  ProductModuleType,
} from '../../AggregatedProductModule';
import { ProductsGrid } from '../../../productFilter/products';
import { ArticleCard } from '../../../productFilter/cards';
import { getDiscoveryContentByType, isFilterEnabled } from '../../../helpers';
import {
  DiscoveryFeaturedTypes,
  DiscoveryArticle,
} from '../../../../../../store/features/discovery';
import {
  DiscoveryContentTypes,
  HubProductFilterPosition,
} from '../../../../../../constants';
import { PropsWithTestId } from '../../../../../../types';
import { useHubContext } from '../../../context/hubContext';
import { useRootSelector } from '../../../../../../store/hooks';
import { selectAudienceHubPrimaryTopic } from '../../../../audienceHub/store/features/options';
import ProductsBlogGrid, {
  ProductCardSizes,
} from '../../../productFilter/products/ProductsBlogGrid';
import ArticleBlogCard from '../../../productFilter/cards/ArticleBlogCard';

import styles from './ArticlesModule.module.scss';

export enum ArticlesModuleDisplayTypes {
  'INFORMACONNECT' = 'INFORMACONNECT',
  'FAST_FORUMS' = 'FAST_FORUMS',
  'INFORMACONNECT_BLOG_LAYOUT' = 'INFORMACONNECT_BLOG_LAYOUT',
}

export enum ArticleRowsAmount {
  FOUR = 1,
  EIGHT = 2,
  TWELVE = 3,
  TWENTY_FOUR = 6,
}

export enum ArticlesListAmountForBlogLayout {
  FOUR = 0,
  EIGHT = 1,
  TWELVE = 5,
  TWENTY_FOUR = 17,
}

export interface ArticlesModuleType extends ProductModuleType {
  displayStyle: ArticlesModuleDisplayTypes;
  amountFeaturedArticles: keyof typeof ArticleRowsAmount;
}

export interface ArticlesModuleProps {
  module: ArticlesModuleType;
}

export default function ArticlesModule(
  props: PropsWithTestId<ArticlesModuleProps>,
) {
  const { t } = useTranslation();
  const { module, testId = 'hub-articles-module' } = props;
  const { brandName } = useHubContext();

  const {
    amountFeaturedArticles,
    displayStyle,
    discovery: { records = [] } = {},
    searchQueryData,
    filterPosition,
  } = module;
  const FILTER_LEFT = filterPosition === HubProductFilterPosition.LEFT;
  const FILTER_TOP = filterPosition === HubProductFilterPosition.TOP;
  const filterEnabled = isFilterEnabled(searchQueryData.facetsConfig);
  const showLeftFilter = filterEnabled && FILTER_LEFT;
  const showTopFilter = filterEnabled && FILTER_TOP;
  const subBrands = useMemo(() => {
    const brands = searchQueryData.subBrands || [];
    return brands.length > 0 ? brands : [brandName];
  }, [brandName, searchQueryData.subBrands]);
  const mainFilterTopic = useRootSelector(selectAudienceHubPrimaryTopic);

  const defaultValues = useMemo(
    () => ({
      subBrands,
      availableSubBrands: subBrands,
      sectors: [],
      topics: [],
      articleTypes: [],
      mainFilterTopic,
    }),
    [mainFilterTopic, subBrands],
  );

  const { results = [], count: resultsCount = 0 } =
    getDiscoveryContentByType<DiscoveryArticle>(
      DiscoveryContentTypes.ARTICLE,
      records,
    );

  const products = useMemo(() => {
    return results.filter(
      (item) => item.featuredType !== DiscoveryFeaturedTypes.LARGE,
    );
  }, [results]);

  const featuredProducts = useMemo(() => {
    return results.filter(
      (item) => item.featuredType === DiscoveryFeaturedTypes.LARGE,
    );
  }, [results]);

  const inlineProductsCount = 6;
  const inlineProductsSlotsCount = 2;
  const blogLargeArticlesStartIndex = 0;
  const blogMediumArticlesStartIndex = 1;
  const blogSmallArticlesStartIndex = 3;
  const blogListArticlesStartIndex = 7;
  const rowsCount = ArticleRowsAmount[amountFeaturedArticles];
  const isBlogLayout =
    displayStyle === ArticlesModuleDisplayTypes.INFORMACONNECT_BLOG_LAYOUT;
  const countPerRow = showLeftFilter ? 3 : 4;
  const amountOfFeaturedArticles =
    rowsCount * countPerRow - inlineProductsSlotsCount;

  const blogListProducts = isBlogLayout
    ? results.slice(
        blogListArticlesStartIndex,
        blogListArticlesStartIndex +
          ArticlesListAmountForBlogLayout[amountFeaturedArticles],
      )
    : [];

  return (
    <AggregatedProductModule
      module={module}
      moduleName="articles"
      brandName={brandName}
      defaultValues={defaultValues}
      resultsCount={resultsCount}
      showLeftFilter={showLeftFilter}
      showTopFilter={showTopFilter}
      showMoreButtonURL="/articles"
      bottomShowMoreButtonLabel={t(
        'site.article.bottom-show-more.button.label',
      )}
      topShowMoreButtonLabel={t('site.article.top-show-more.button.label')}
      testId={testId}
    >
      {displayStyle === ArticlesModuleDisplayTypes.FAST_FORUMS && (
        <ProductsGrid<DiscoveryArticle>
          products={products.slice(0, rowsCount * countPerRow)}
          featuredProducts={featuredProducts}
          renderProduct={(product) => <ArticleCard data={product} />}
          renderFeaturedProduct={(product) => (
            <ArticleCard
              data={product}
              cardType="featured"
              showFilter={filterEnabled}
            />
          )}
          productsCountPerRow={showLeftFilter ? 'three' : 'four'}
        />
      )}

      {displayStyle === ArticlesModuleDisplayTypes.INFORMACONNECT && (
        <ProductsGrid<DiscoveryArticle>
          featuredProducts={featuredProducts}
          products={products.slice(0, amountOfFeaturedArticles)}
          inlineProducts={products.slice(
            amountOfFeaturedArticles,
            amountOfFeaturedArticles + inlineProductsCount,
          )}
          renderProduct={(product) => <ArticleCard data={product} />}
          renderInlineProduct={(product) => (
            <ArticleCard data={product} cardType="inline" />
          )}
          renderFeaturedProduct={(product) => (
            <ArticleCard
              data={product}
              cardType="featured"
              showFilter={filterEnabled}
            />
          )}
          productsCountPerRow={showLeftFilter ? 'three' : 'four'}
        />
      )}

      {displayStyle ===
        ArticlesModuleDisplayTypes.INFORMACONNECT_BLOG_LAYOUT && (
        <>
          <ProductsBlogGrid
            largeProductItems={results.slice(
              blogLargeArticlesStartIndex,
              blogMediumArticlesStartIndex,
            )}
            mediumProductItems={results.slice(
              blogMediumArticlesStartIndex,
              blogSmallArticlesStartIndex,
            )}
            smallProductItems={results.slice(
              blogSmallArticlesStartIndex,
              blogListArticlesStartIndex,
            )}
            renderProduct={(renderProps) => {
              const { productSize, productIndex } = renderProps;
              const displayImageMobileOnly =
                productSize === ProductCardSizes.medium && productIndex !== 0;

              return (
                <ArticleBlogCard
                  productSize={
                    productSize === ProductCardSizes.medium
                      ? ProductCardSizes.small
                      : productSize
                  }
                  data={renderProps.product}
                  displayImageMobileOnly={displayImageMobileOnly}
                />
              );
            }}
          />

          {blogListProducts.length > 0 && (
            <div className="row">
              <div className="col-xs-12">
                {blogListProducts.map((product) => (
                  <div className={styles.blogCardWrapper} key={product.id}>
                    <ArticleBlogCard data={product} variant="list" fullWidth />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </AggregatedProductModule>
  );
}
